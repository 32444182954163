<template>
<main id="main" class="site-main site-main-detail" v-for="(detail,index) in pageblock" :key="index">
    <div class="postercard">
        <div class="postercard__img">
            <img :src="urlPath+'uploads/media/page/'+detail.image">
        </div>
    </div>
    <section class="blog">
        <div class="container">
            <div class="row">
                <div class="col xs-12 sm-8 md-9">
                    <div class="breadcrumb">
                        <ul>
                            <li><router-link :to="{name:'Home'}">Home</router-link></li>
                            <li><span class="live">{{detail.title}}</span></li>
                        </ul>
                    </div>
                    <h1 class="folio__title">{{detail.title}}</h1>
                    <div class="reviser" v-html="detail.description">

                    </div>
                </div>
                <div class="col xs-12 sm-4 md-3">
                    
                    <div class="related">
                        <h2 class="related__title"><strong>Awesome</strong> Package</h2>
                        <awesome />
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
</template>

<script>
import Awesomepackage from "./Innercomponent/Awesomepackageinner.vue";
import axios from 'axios';
export default ({
    data() {
        return {
            pageblock: undefined,
            apiPath: process.env.VUE_APP_API,
            urlPath: process.env.VUE_APP_PATH,
            error: [],

        }
    },
    async mounted() {
        this.normalPage(this.$route.params.slug);
        if (this.$route.params.title) {
            this.form.Subject = this.$route.params.title;
        }

    },
    methods: {
        normalPage(slugurl) {
            axios.get(this.apiPath + 'showpage/' + slugurl)
                .then((response) => {
                    this.pageblock = response.data;
                    const descEl = document.querySelector('head meta[name="description"]');
                    const keyEl = document.querySelector('head meta[name="keywords"]');
                    const titleEl = document.querySelector('head title');

                    descEl.setAttribute('content', response.data[0]['seo_description']);
                    keyEl.setAttribute('content', response.data[0]['seo_keyword']);
                    titleEl.textContent = response.data[0]['seo_title'] + " :: Nepal Alternative Treks";
                })
        },
    },
    watch: {
        '$route.params.slug'(newSlug) {
            this.normalPage(newSlug);
        }
    },
    components: {
        'awesome': Awesomepackage,
    }
})
</script>
